import store from "@/state/store";
import { useFastWay } from "@/state/modules/fastway";
import Login from '@/views/auth/Login';
import ValidateToken from '@/views/auth/ValidateToken';
import Home from '@/views/Home.vue';
import Parameters from '@/views/infra/parameters/Parameters.vue';
import Parameter from '@/views/infra/parameters/Parameter.vue';
import Lists from '@/views/infra/lists/Lists.vue';
import List from '@/views/infra/lists/List.vue';
import Roles from '@/views/infra/roles/Roles.vue';
import Role from '@/views/infra/roles/Role.vue';
import Users from '@/views/infra/users/Users.vue';
import User from '@/views/infra/users/User.vue';
import Organizations from '@/views/infra/organizations/Organizations';
import Organization from '@/views/infra/organizations/Organization';
import Locations from '@/views/infra/locations/Locations';
import Location from '@/views/infra/locations/Location';
import SBUs from '@/views/infra/sbus/SBUs';
import SBU from '@/views/infra/sbus/SBU';
import PaymentMethods from '@/views/sales/paymentMethods/PaymentMethods';
import PaymentMethod from '@/views/sales/paymentMethods/PaymentMethod';
import Warehouses from '@/views/stock/warehouses/Warehouses';
import Warehouse from '@/views/stock/warehouses/Warehouse';
import StockDocumentTypes from '@/views/stock/documentTypes/StockDocumentTypes';
import StockDocumentType from '@/views/stock/documentTypes/StockDocumentType';
import ProductCategories from '@/views/stock/productCategories/ProductCategories.vue';
import ProductCategory from '@/views/stock/productCategories/ProductCategory.vue';
import Products from '@/views/catalogs/products/Products.vue';
import Product from '@/views/catalogs/products/Product.vue';
import MeasureUnits from '@/views/stock/measureUnits/MeasureUnits';
import MeasureUnit from '@/views/stock/measureUnits/MeasureUnit';
import StockTransactions from '@/views/stock/transactions/StockTransactions';
import StockTransaction from '@/views/stock/transactions/StockTransaction';
import StockDate from '@/views/stock/reports/StockDate';
import Kardex from '@/views/stock/reports/Kardex';
import SaleDocumentTypes from '@/views/sales/documentTypes/SaleDocumentTypes';
import SaleDocumentType from '@/views/sales/documentTypes/SaleDocumentType';
import FELProviders from '@/views/sales/felProviders/FELProviders';
import FELProvider from '@/views/sales/felProviders/FELProvider';
import Partners from '@/views/sales/partners/Partners';
import Partner from '@/views/sales/partners/Partner';
import PriceLists from '@/views/sales/priceLists/PriceLists';
import PriceList from '@/views/sales/priceLists/PriceList';
import SaleOrders from '@/views/sales/transactions/SaleOrders';
import SaleOrder from '@/views/sales/transactions/SaleOrder';
import Invoices from '@/views/sales/transactions/Invoices';
import Invoice from '@/views/sales/transactions/Invoice';
import AnforaPOS from '@/views/sales/pos/AnforaPOS';
import SaleOrderReports from '@/views/sales/reports/SaleOrdersReport';

export default [
//
//  INFRAESTRUCTURE
//  ===============
//
{
  path: "/infra/parameters",
  name: "parameters",
  meta: { title: "Parámetros", authRequired: true },
  component: Parameters
},
{
  path: "/infra/parameter/:id",
  name: "parameter",
  meta: { title: "Parámetro", authRequired: true },
  component: Parameter
},
{
  path: "/infra/lists",
  name: "lists",
  meta: { title: "Listas", authRequired: true },
  component: Lists
},
{
  path: "/infra/lists/:id",
  name: "list",
  meta: { title: "Lista", authRequired: true },
  component: List
},
{
  path: "/infra/roles",
  name: "roles",
  meta: { title: "Roles", authRequired: true },
  component: Roles
},
{
  path: "/infra/roles/:id",
  name: "role",
  meta: { title: "Rol", authRequired: true },
  component: Role
},
{
  path: "/infra/users",
  name: "users",
  meta: { title: "Usuarios", authRequired: true },
  component: Users
},
{
  path: "/infra/users/:id",
  name: "user",
  meta: { title: "Usuario", authRequired: true },
  component: User
},
{
  path: "/infra/organizations",
  name: "organizations",
  meta: { title: "Empresas", authRequired: true },
  component: Organizations
},
{
  path: "/infra/organizations/:id",
  name: "organization",
  meta: { title: "Empresa", authRequired: true },
  component: Organization
},
{
  path: "/infra/locations",
  name: "locations",
  meta: { title: "Ubicaciones", authRequired: true },
  component: Locations
},
{
  path: "/infra/locations/:id",
  name: "location",
  meta: { title: "Ubicación", authRequired: true },
  component: Location
},
{
  path: "/infra/sbus",
  name: "sbus",
  meta: { title: "SBUs", authRequired: true },
  component: SBUs
},
{
  path: "/infra/sbus/:id",
  name: "sbu",
  meta: { title: "SBU", authRequired: true },
  component: SBU
},
{
    path: "/login",
    name: "login",
    component: Login,
    meta:  { title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["fastway/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "home" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/validate_access/:token",
    name: "validateAccess",
    component: ValidateToken
  },
  {
    path: "/",
    name: "home",
    meta: {
      title: "Tablero",
      authRequired: true,
    },
    component: Home,
  },
//
//  STOCK
//  =====
//
{
  path: "/stock/catalogs/measure_units",
  name: "measureUnits",
  meta: { title: "Unidades de medida", authRequired: true },
  component: MeasureUnits
},
{
  path: "/stock/catalogs/measure_units/:id",
  name: "measureUnit",
  meta: { title: "Unidad de medida", authRequired: true },
  component: MeasureUnit
},
{
  path: "/stock/catalogs/warehouses",
  name: "warehouses",
  meta: { title: "Bodegas", authRequired: true },
  component: Warehouses
},
{
  path: "/stock/catalogs/warehouses/:id",
  name: "warehouse",
  meta: { title: "Bodega", authRequired: true },
  component: Warehouse
},
{
  path: "/stock/catalogs/document_types",
  name: "stockDocumentTypes",
  meta: { title: "Tipos de documento", authRequired: true },
  component: StockDocumentTypes
},
{
  path: "/stock/catalogs/document_types/:id",
  name: "stockDocumentType",
  meta: { title: "Tipo de documento", authRequired: true },
  component: StockDocumentType
},
{
  path: "/stock/catalogs/product_categories",
  name: "productCategories",
  meta: { title: "Categorías de productos", authRequired: true },
  component: ProductCategories
},
{
  path: "/stock/catalogs/product_categories/:id",
  name: "productCategory",
  meta: { title: "Categoría de productos", authRequired: true },
  component: ProductCategory
},
{
  path: "/stock/catalogs/products",
  name: "products",
  meta: { title: "Productos", authRequired: true },
  component: Products
},
{
  path: "/stock/catalogs/products/:id",
  name: "product",
  meta: { title: "Producto", authRequired: true },
  component: Product
},
{
  path: "/stock/operations/transactions",
  name: "stockTransactions",
  meta: { title: "Transacciones", authRequired: true },
  component: StockTransactions
},
{
  path: "/stock/operations/transactions/:id",
  name: "stockTransaction",
  meta: { title: "Transaccion de inventario", authRequired: true },
  component: StockTransaction
},
{
  path: "/stock/reports/stock",
  name: "stockDateReport",
  meta: { title: "Existencias", authRequired: true },
  component: StockDate
},
{
  path: "/stock/reports/kardex",
  name: "kardexReport",
  meta: { title: "Kardex", authRequired: true },
  component: Kardex
},
//
//  SALES
//  =====
//
{
  path: "/sales/catalogs/document_types",
  name: "saleDocumentTypes",
  meta: { title: "Tipos de documento", authRequired: true },
  component: SaleDocumentTypes
},
{
  path: "/sales/catalogs/document_types/:id",
  name: "saleDocumentType",
  meta: { title: "Tipo de documento", authRequired: true },
  component: SaleDocumentType
},
{
  path: "/sales/catalogs/fel_providers",
  name: "felProviders",
  meta: { title: "Proveedores FEL", authRequired: true },
  component: FELProviders
},
{
  path: "/sales/catalogs/payment_methods",
  name: "paymentMethods",
  meta: { title: "Formas de pago", authRequired: true },
  component: PaymentMethods
},
{
  path: "/sales/catalogs/payment_methods/:id",
  name: "paymentMethod",
  meta: { title: "Forma de pago", authRequired: true },
  component: PaymentMethod
},
{
  path: "/sales/catalogs/fel_providers/:id",
  name: "felProvider",
  meta: { title: "Proveedor FEL", authRequired: true },
  component: FELProvider
},
{
  path: "/sales/catalogs/salesmen",
  name: "salesmen",
  meta: { title: "Vendedores", authRequired: true, partnerType: 'v' },
  component: Partners
},
{
  path: "/sales/catalogs/salesmen/:id",
  name: "salesman",
  meta: { title: "Vendedor", authRequired: true, partnerType: 'v' },
  component: Partner
},
{
  path: "/sales/catalogs/customers",
  name: "customers",
  meta: { title: "Clientes", authRequired: true, partnerType: 'c' },
  component: Partners
},
{
  path: "/sales/catalogs/customers/:id",
  name: "customer",
  meta: { title: "Cliente", authRequired: true, partnerType: 'c' },
  component: Partner
},
{
  path: "/sales/catalogs/products",
  name: "saleProducts",
  meta: { title: "Productos", authRequired: true, urlSection: 'sales', module: 'Ventas', detailRoute: 'salesProduct' },
  component: Products
},
{
  path: "/sales/catalogs/products/:id",
  name: "salesProduct",
  meta: { title: "Producto", authRequired: true },
  component: Product
},
{
  path: "/sales/catalogs/price_lists",
  name: "priceLists",
  meta: { title: "Listas de precios", authRequired: true },
  component: PriceLists
},
{
  path: "/sales/catalogs/price_lists/:id",
  name: "priceList",
  meta: { title: "Lista de precios", authRequired: true },
  component: PriceList
},
{
  path: "/sales/transactions/sale_orders",
  name: "saleOrders",
  meta: { title: "Pedidos de venta", authRequired: true },
  component: SaleOrders
},
{
  path: "/sales/transactions/sale_orders/:id",
  name: "saleOrder",
  meta: { title: "Pedido de venta", authRequired: true },
  component: SaleOrder
},
{
  path: "/sales/transactions/invoices",
  name: "invoices",
  meta: { title: "Facturas", authRequired: true },
  component: Invoices
},
{
  path: "/sales/transactions/invoices/:id",
  name: "invoice",
  meta: { title: "Factura", authRequired: true },
  component: Invoice
},
{
  path: "/sales/transactions/anfora_pos",
  name: "AnforaPOS",
  meta: { title: "Punto de venta", authRequired: true },
  component: AnforaPOS
},
{
  path: "/sales/reports/orders",
  name: "saleOrdersReport",
  meta: { title: "Reporte de ventas", authRequired: true },
  component: SaleOrderReports
},
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout", authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        const fwStore = useFastWay();
        fwStore.userInfo = {
          id: 0,
          uid: 0,
          userId: 0,
          login: '',
          userName: '',
          displayName: '',
          image: '',
          about: '',
          photoURL: '',
          status: '',
          userRole: '',
          organizationId: 0,
          organizationName: '',
          token: ''
        };
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute ? { name: "home" } : { ...routeFrom }
        );
      },
    },
    component: () => import("../views/auth/logout/basic")
  },
];