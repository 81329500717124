<script setup>
import { ref, computed, onMounted } from "vue";
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios';
import { useLoading } from 'vue-loading-overlay'
import Layout from "@/layouts/mainfw.vue";
import PageHeader from "@/components/page-header";
import { useFastWay } from "@/state/modules/fastway";
import Dialog from '@/views/components/Dialog.vue';
import Confirm from '@/views/components/Confirm.vue';
import { TextBoxComponent } from "@syncfusion/ej2-vue-inputs";
import { NumericTextBoxComponent } from "@syncfusion/ej2-vue-inputs";
import { DropDownListComponent } from "@syncfusion/ej2-vue-dropdowns";
import { CheckBoxComponent } from "@syncfusion/ej2-vue-buttons";

const apiFW = require('@/api.config.js');
const router = useRouter();
const route = useRoute();
const store = useFastWay();
const loader = useLoading();
let id = ref(0);
let loaded = ref(false);
let editing = ref(false);
let dialog = ref(null);
let dialogDelete = ref(null);
let confirm = ref(null);
let data = ref(null);
let productTypes = ref([]);
let categories = ref([]);
let measureUnits = ref([]);
let statuses = ref([]);
let fileInput = ref(null);
let data_local = ref({
  id: 0,
  code: '',
  name: '',
  barcode: '',
  alternateCode: '',
  sbu: '',
  description: '',
  type: '',
  categoryId: 0,
  measureUnitId: 0,
  price: 0,
  comments: '',
  stockControl: false,
  fabricate: false,
  availablePOS: false,
  statusId: 'ACT'
});

const title = "Producto";
const viewParms = computed({
  get() {
    return store.viewParameters.products;
  }
});
const items = viewParms.value.breadcrumb ? viewParms.value.breadcrumb : [
  {
    text: "Inventario",
    href: "/",
  },
  {
    text: "Productos",
    href: "/stock/catalogs/products",
  },
  {
    text: "Producto",
    active: true,
  }
];

const api = computed({
    get() {
      return apiFW.default.apiURL;
    }
});

onMounted(async () => {
  id.value = parseInt(route.params.id);
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Cargando catálogos'});
  const params = {
    userId: store.userInfo.userId,
    token: store.userInfo.token,
    limit: 100000,
    startPage: 1,
    order: 'name',
    orderDirection: 'ascending'
  };
  await axios
  .post(`${api.value}/lists/get_list`, {
    userId: store.userInfo.userId,
    token: store.userInfo.token,
    list: 'ESTADOPRODUCTO'
  })
  .then(response => {
    if (response.data.success) {
      statuses.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar estados de ubicación', response.data.message);
    }
    return axios
      .post(`${api.value}/lists/get_list`, {
        userId: store.userInfo.userId,
        token: store.userInfo.token,
        list: 'TIPOPRODUCTO'
      });
  })
  .then(response => {
    if (response.data.success) {
      productTypes.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar tipos de producto', response.data.message);
    }
    return axios
      .post(`${api.value}/stock/measure_units/list`, params);
  })
  .then(response => {
    if (response.data.success) {
      measureUnits.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar unidades de medida', response.data.message);
    }
    return axios
      .post(`${api.value}/stock/products/categories/list`, params);
  })
  .then(response => {
    if (response.data.success) {
      categories.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar categorías de productos', response.data.message);
    }
    getData(id.value);
    loading.hide();
  })
  .catch(error => {
      let errorMessage = '';
      if (error.message) {
        errorMessage = error.message;
      } else if (error.response) {
        errorMessage = error.response.data.ErrorMessage;
      } else {
        errorMessage = 'Error de conectividad al cargar catálogo';
      }
      loading.hide();
      dialog.value.show('error', 'Error al cargar catálogos', errorMessage);
  });
});

const getData = async (id) => {
  if (id == '0') {
    loaded.value = true;
    editing.value = true;
  } else {
    const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Cargando datos de producto'});
    await axios
    .post(`${api.value}/stock/products/data`, {id, context: store.userInfo})
    .then(response => {
      if (response.data.success) {
        if (response.data.data) {
          data.value = response.data.data;
          data_local.value = JSON.parse(JSON.stringify(response.data.data));
        }
      } else {
        dialog.value.show('error', 'Error interno al cargar producto', response.data.message);
      }
      loaded.value = true;
      loading.hide();
    })
    .catch(error => {
      loaded.value = true;
      loading.hide();
      dialog.value.show('error', `Error al cargar producto ${id}`, error.message);
    });
  }
};

const clearImage = () => {
  data_local.value.image = '';
}

const selectImage = () => {
  fileInput.value.click();
}

const pickFile = () => {
  const file = fileInput.value.files;
  if (file && file[0]) {
    const reader = new FileReader;
    reader.onload = e => {
      data_local.value.image = e.target.result;
    };
    reader.readAsDataURL(file[0]);
    // this.$emit('input', file[0]);
  }
}

const resetData = () => {
  if (id.value == '0') {
    router.push({name: 'products'});
  } else {
    data_local.value = JSON.parse(JSON.stringify(data.value));
    editing.value = false;
  }
};

const validateForm = () => {
  let validated = false;
  if (!data_local.value.code || data_local.value.code == '') {
    dialog.value.show('error', 'Error al guardar producto', 'Debe especificar un código');
  } else if (!data_local.value.name || data_local.value.name == '') {
    dialog.value.show('error', 'Error al guardar producto', 'Debe especificar nombre del producto');
  } else {
    validated = true;
  }
  return validated;
};

const save = async () => {
  if (validateForm()) {
    const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Guardando datos de producto'});
    data_local.value.id = id.value;
    data_local.value.context = store.userInfo;
    data_local.value.createdBy = store.userInfo.userId;
    await axios
    .post(`${api.value}/stock/products/save`, data_local.value)
    .then(response => {
      loading.hide();
      if (response.data.success) {
        data.value = JSON.parse(JSON.stringify(data_local.value));
        editing.value = false;
        dialog.value.show('success', 'Información', 'Datos han sido actualizados');
        if (id.value== 0) {
          router.push({
            name: 'product',
            params: {id: response.data.data.id}
          });
        }
      } else {
        dialog.value.show('error', 'Error al actualizar', response.data.message);
      }
    })
    .catch(error => {
      loading.hide();
      dialog.value.show('error',  `Error al actualizar producto ${id.value}`, error.message);
    });
  }
};

const remove = async () => {
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Eliminando producto'});
  const parms = {
    id: id.value,
    context: store.userInfo,
    createdBy: store.userInfo.userId
  };
  await axios
  .post(`${api.value}/stock/products/delete`, parms)
  .then(response => {
    loading.hide();
    if (response.data.success) {
      dialogDelete.value.show('success', 'Información', 'Producto fue eliminado correctamente');
    } else {
      dialog.value.show('error', 'Error al eliminar', response.data.message);
    }
  })
  .catch(error => {
    loading.hide();
    dialog.value.show('error', `Error al eliminar producto ${id.value}`, error.message);
  });
};

const add = () => {
  router.push({
    name: 'product',
    params: {id: 0}
  });
  id.value = 0;
  data_local.value = {
    id: 0,
    code: '',
    name: '',
    barcode: '',
    alternateCode: '',
    sbu: '',
    description: '',
    type: '',
    categoryId: 0,
    measureUnitId: 0,
    price: 0,
    comments: '',
    stockControl: false,
    fabricate: false,
    availablePOS: false,
    statusId: 'ACT'
  };
  editing.value = true;
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
     <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center align-middle row ms-0 me-0 mb-2">
                        <div v-if="!editing" class="col d-flex justify-content-start">
                          <b-button variant="success" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Agregar usuario" @click="add"><i class="las la-plus label-icon"></i> Agregar</b-button>
                        </div>
                        <div class="col"/>
                        <div class="input-group col justify-content-end">
                          <b-button v-if="editing" variant="primary" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Guardar cambios efectuados" @click="save"><i class="las la-save label-icon"></i> Guardar</b-button>
                          <b-button v-if="editing" variant="warning" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Descartar cambios efectuados" @click="resetData"><i class="las la-undo label-icon"></i> Cancelar</b-button>
                          <b-button v-if="!editing" variant="primary" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Editar información" @click="editing = true"><i class="las la-edit label-icon"></i> Editar</b-button>
                          <b-button v-if="!editing" variant="danger" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Eliminar producto" @click="confirm.show('Confirme', '¿Está seguro de eliminar este producto?')"><i class="las la-trash label-icon"></i> Eliminar</b-button>
                        </div>
                    </div>
                    <hr>
                    <div class="row mb-4">
                      <div class="col-4">
                        <div class="form-floating">
                          <input type="text" class="form-control form-control-lg" :class="{'bg-light': !editing, 'bg-white': editing}" id="role" v-model="data_local.code" placeholder="Código" :disabled="!editing"/>
                          <label for="role">Código</label>
                        </div>
                      </div>
                    </div>
                    <div class="mb-1>">
                      <TextBoxComponent v-model:value="data_local.name" floatLabelType="Auto" placeholder="Nombre" :disabled="!editing"/>
                    </div>
                    <ul class="nav nav-pills arrow-navtabs nav-uccess bg-light mt-2 mb-3" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link active" data-bs-toggle="tab" href="#general-info" role="tab">
                          Información general
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#images" role="tab">
                          Imágenes
                        </a>
                      </li>
                    </ul>
                    <div class="tab-content text-muted">
                      <div class="tab-pane active px-2" id="general-info" role="tabpanel">
                        <div class="mb-3>">
                          <TextBoxComponent v-model:value="data_local.description" floatLabelType="Auto" placeholder="Descripción" :enabled="editing" :multiline="true"/>
                        </div>
                        <div class="row mb-1">
                          <div class="col me-1">
                            <DropDownListComponent
                              v-model:value="data_local.type"
                              floatLabelType="Auto"
                              placeholder="Tipo de producto"
                              highlight=true
                              :dataSource="productTypes"
                              :fields="{text: 'value', value: 'code'}"
                              :enabled="editing"
                            />
                          </div>
                          <div class="col me-1">
                            <DropDownListComponent
                              v-model:value="data_local.categoryId"
                              floatLabelType="Auto"
                              placeholder="Categoría"
                              highlight=true
                              :dataSource="categories"
                              :fields="{text: 'name', value: 'id'}"
                              :enabled="editing"
                            />
                          </div>
                          <div class="col me-1">
                            <DropDownListComponent
                              v-model:value="data_local.measureUnitId"
                              floatLabelType="Auto"
                              placeholder="Unidad de medida"
                              highlight=true
                              :dataSource="measureUnits"
                              :fields="{text: 'description', value: 'id'}"
                              :enabled="editing"
                            />
                          </div>
                        </div>
                        <div class="row mb-1>">
                          <div class="col-1 me-1">
                            <NumericTextBoxComponent v-model:value="data_local.price" floatLabelType="Auto" placeholder="Precio" format="N4" decimals=4 min=0.0001 :disabled="!editing"/>
                          </div>
                          <div class="col-2 me-1">
                            <TextBoxComponent v-model:value="data_local.barcode" floatLabelType="Auto" placeholder="EAN13" :disabled="!editing"/>
                          </div>
                          <div class="col-2 me-1">
                            <TextBoxComponent v-model:value="data_local.alternateCode" floatLabelType="Auto" placeholder="Código alterno" :disabled="!editing"/>
                          </div>
                          <div class="col-1 me-1">
                            <DropDownListComponent
                              v-model:value="data_local.statusId"
                              floatLabelType="Auto"
                              placeholder="Estado"
                              highlight=true
                              :dataSource="statuses"
                              :fields="{text: 'value', value: 'code'}"
                              :enabled="editing"
                            />
                          </div>
                          <div class="col-2 mt-3 me-1">
                            <CheckBoxComponent label="Maneja existencias" v-model="data_local.stockControl" :checked="data_local.stockControl" :disabled="!editing" />
                          </div>
                          <div class="col-2 mt-3">
                            <CheckBoxComponent label="Disponible en punto de venta" v-model="data_local.availablePOS" :checked="data_local.availablePOS" :disabled="!editing" />
                          </div>
                          <div class="col-1 mt-3">
                            <CheckBoxComponent label="Fabricar" v-model="data_local.fabricate" :checked="data_local.fabricate" :disabled="!editing" />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col">
                            <TextBoxComponent v-model:value="data_local.comments" floatLabelType="Auto" placeholder="Comentarios" :enabled="editing" :multiline="true"/>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane px-2" id="images" role="tabpanel">
                        <div class="row mb-2">
                          <div class="col-6 me-2">
                            <div class="card">
                              <img
                                v-show="data_local.image != ''"
                                class="card-img-top img-fluid"
                                :src="data_local.image"
                                alt="Producto con imagen no válida"
                              />
                              <div class="card-body">
                                <h4 class="card-title mb-2">Imagen original del producto</h4>
                                <input
                                  ref="fileInput"
                                  type="file"
                                  accept="image/png"
                                  @input="pickFile"
                                  class="opacity-0 absolute"
                                />
                              </div>
                              <div class="card-footer">
                                <div>
                                  <b-button v-if="editing" variant="primary" class="btn-label waves-effect waves-light me-2" v-b-tooltip.hover title="Seleccionar imagen" @click="selectImage"><i class="las la-image label-icon"></i> Seleccionar imagen</b-button>
                                  <b-button v-if="editing" variant="danger" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Eliminar imagen" @click="clearImage"><i class="las la-trash-alt label-icon"></i> Eliminar imagen</b-button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="card" style="width: 200px;">
                              <img
                                v-show="data_local.smallImage != ''"
                                class="card-img-top img-fluid"
                                :src="data_local.smallImage"
                                alt="Producto con imagen no válida"
                              />
                              <div class="card-body">
                                <h4 class="card-title mb-2">Imagen redimensionada</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Dialog ref="dialog"/>
    <Dialog ref="dialogDelete" @accept="router.push({name: 'products'});"/>
    <Confirm ref="confirm" @accept="remove"/>
  </Layout>
</template>

<style>
@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-inputs/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";

.e-input-group .e-input[disabled], .e-input-group.e-control-wrapper .e-input[disabled], .e-input-group.e-disabled, .e-input-group.e-control-wrapper.e-disabled, .e-float-input input[disabled], .e-float-input.e-control-wrapper input[disabled], .e-float-input textarea[disabled], .e-float-input.e-control-wrapper textarea[disabled], .e-float-input.e-disabled, .e-float-input.e-control-wrapper.e-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.75);
    color: rgba(0, 0, 0, 0.75);
}
</style>