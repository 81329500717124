<script setup>
import { ref, computed, onMounted } from "vue";
import axios from 'axios';
import { useLoading } from 'vue-loading-overlay'
import Layout from "@/layouts/mainfw.vue";
import PageHeader from "@/components/page-header";
import { useFastWay } from "@/state/modules/fastway";
import Dialog from '@/views/components/Dialog.vue';
import Confirm from '@/views/components/Confirm.vue';

const apiFW = require('@/api.config.js');
const store = useFastWay();
const loader = useLoading();
let dialog = ref(null);
let dialogPlacedOrder = ref(null);
let confirm = ref(null);
let parameters = ref([]);
let categories = ref([]);
let products = ref([]);
let paymentMethods = ref([]);
let storeInfo = ref(null);
let placeOrderDisabled = ref(false);
let coupons = ref([]);
let data_local = ref({
  id: 0,
  phone: '',
  name: '',
  vat: 'CF',
  vatName: 'Consumidor Final',
  active: '',
  donor: '',
  society: '',
  autosale: '',
  date: new Date(),
  amount: 0,
  totalPaid: 0,
  change: 0,
  storeId: '',
  couponCode: '',
  products: [],
  coupons: [],
  cashPayments: []
});
let currentTabIndex = ref(0);
let currentCategory = ref(0);
let search = ref('');
const title = "Nueva orden";
const items = [
  {
    text: "Ventas",
    href: "/",
  },
  {
    text: "Nueva orden",
    active: true,
  }
];

const api = computed({
  get() {
    return apiFW.default.apiURL;
  }
});

const apiVAT = computed({
  get() {
    let value =  '';
    const param  = parameters.value.filter(p => p.parameter == 'PUNTOVENTA' && p.code == 'APIVALIDACIONNIT');
    if (param && param.length > 0) {
      value = param[0].value;
    }
    return value;
  }
});

const maxQuantity = computed({
  get() {
    let value = 0;
    const param  = parameters.value.filter(p => p.parameter == 'PUNTOVENTA' && p.code == 'MAXIMOCANTIDAD');
    if (param && param.length > 0) {
      value = parseInt(param[0].value);
    }
    return value;
  }
})

const allowMixedPayments = computed({
  get() {
    let value = false;
    const param  = parameters.value.filter(p => p.parameter == 'PUNTOVENTA' && p.code == 'PERMITIRPAGOMIXTO');
    if (param) {
      value = (param[0].value.toUpperCase() == 'SI');
    }
    return value;
  }
})

const productsList = computed({
  get() {
    let list = [];
    if (search.value != '') {
      clearCategory();
      list = products.value.filter(p => {
        return p.name.toUpperCase().includes(search.value.trim().toUpperCase());
      });
    } else if (currentCategory.value != 0) {
      list = products.value.filter(p => {
        return p.categoryId == currentCategory.value;
      })
    } else {
      list = [...products.value];
    }
    return list;
  }
});

const change = computed({
  get() {
    return data_local.value.totalPaid - data_local.value.amount;
  }
});

onMounted(async () => {
  const loading = loader.show({ loader: 'bars', color: '#0000FF' }, { after: () => 'Cargando catálogos' });
  const params = {
    parameter: 'PUNTOVENTA',
    value: '',
    userId: store.userInfo.userId,
    token: store.userInfo.token
  };
  await axios
  .post(`${api.value}/parameters/list`, params)
  .then(response => {
    if (response.data.success) {
      parameters.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar parámetros generales', response.data.message);
    }
    return axios.post(`${api.value}/products/categories/list`, {
      userId: store.userInfo.userId,
      token: store.userInfo.token,
      order: 'order',
      availablePOS: 1
    });
  })
  .then(response => {
    if (response.data.success) {
      categories.value = response.data.data.sort((a, b) => a.order - b.order);
    } else {
      dialog.value.show('error', 'Error al cargar categorías de productos', response.data.message);
    }
    const params = {
      userId: store.userInfo.userId,
      token: store.userInfo.token,
      limit: 100000,
      startPage: 1,
      searchPhrase: '',
      order: 'name',
      orderDirection: 'ascending',
      statusId: 'ACT'
    };
    return axios.post(`${api.value}/stock/products/list`, params);
  })
  .then(response => {
    if (response.data.success) {
      products.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar productos', response.data.message);
    }
    return axios.post(`${api.value}/locations/list`, { userId: store.userInfo.userId, token: store.userInfo.token });
  })
  .then(response => {
    if (response.data.success) {
      storeInfo.value = response.data.data[0];
    } else {
      dialog.value.show('error', 'Error al cargar datos de tienda', response.data.message);
    }
    const params = {
      userId: store.userInfo.userId,
      token: store.userInfo.token,
      limit: 100000,
      startPage: 1,
      order: 'order',
      orderDirection: 'ascending'
    };
    return axios.post(`${api.value}/sale/cash_payments/list`, params);
  })
  .then(response => {
    if (response.data.success) {
      paymentMethods.value = response.data.data.sort((a, b) => a.order - b.order);
    } else {
      dialog.value.show('error', 'Error al cargar formas de pago', response.data.message);
    }
    resetData();
    loading.hide();
  })
  .catch(error => {
    let errorMessage = '';
    if (error.message) {
      errorMessage = error.message;
    } else if (error.response) {
      errorMessage = error.response.data.ErrorMessage;
    } else {
      errorMessage = 'Error de conectividad al cargar catálogo';
    }
    loading.hide();
    dialog.value.show('error', 'Error al cargar catálogos', errorMessage);
  });
});

const onVATBlur = event => {
  event.preventDefault();
  data_local.value.vat = data_local.value.vat.trim().toUpperCase();
  if (['C. F.', 'C.F.', 'C.F', 'C-F', 'C/F', 'C\\F'].includes(data_local.value.vat)) {
    data_local.value.vat = 'CF';
  }
  if (data_local.value.vat == 'CF') {
    data_local.value.vatName = 'Consumidor final';
  } else {
    data_local.value.vatName = '';
    const loading = loader.show({ loader: 'bars', color: '#0000FF' }, { after: () => 'Validando NIT, espere por favor' });
    axios.post(apiVAT.value, {
      phoneNumber: '1730',
      vat: data_local.value.vat
    })
    .then(response => {
      loading.hide();
      if (response.data.success) {
        data_local.value.vatName = response.data.data.name;
      } else {
        dialog.value.show('error', 'Error al cargar validar NIT', `No se encontró el NIT ${data_local.value.vat}`);
      }
    })
    .catch(error => {
      loading.hide();
      var errorMessage = '';
      if (error.message) {
        errorMessage = error.message;
      } else if (error.response) {
        errorMessage = error.response.data.ErrorMessage;
      } else {
        errorMessage = 'Error de conectividad'
      }
      dialog.value.show('error', 'Error al cargar validar NIT', errorMessage);
    });
  }
};

const toProducts = () => {
  if (data_local.value.vatName == '') {
    dialog.value.show('error', 'Error validando información', 'Debe seleccionar un NIT correcto');
  } else {
    if (data_local.value.active == '') {
      data_local.value.phone = '';
    }
    currentTabIndex.value = 1;
  }
}

const clearCategory = () => {
  currentCategory.value = 0;
}

const setCategory = id => {
  search.value = '';
  currentCategory.value = currentCategory.value == id ? 0 : id;
}

const addToOrder = product => {
  const index = data_local.value.products.findIndex(p => p.productId == product.id);
  if (index < 0) {
    data_local.value.products.push({
      productId: product.id,
      productCode: product.code,
      productName: product.name,
      description: product.name,
      quantity: 1,
      unitPrice: product.price,
      amount: product.price,
      warehouseId: 1,
      statusId: 'OPE'
    });
  } else {
    data_local.value.products[index].quantity++;
    data_local.value.products[index].amount = data_local.value.products[index].quantity * data_local.value.products[index].unitPrice;
  }
  priceOrder();
}

const removeProduct = product => {
  const index = data_local.value.products.findIndex(p => p.code == product.code);
  if (index >= 0) {
    data_local.value.products[index].quantity = 0;
  }
  priceOrder();
}

const calculateAmount = () => {
  const products = [];
  data_local.value.amount = 0;
  data_local.value.products.forEach(item => {
    if (item.quantity < 0) {
      item.quantity = Math.abs(item.quantity);
    }
    if (item.quantity > maxQuantity.value) {
      item.quantity = maxQuantity.value;
    }
    if (item.quantity > 0) {
      item.amount = item.quantity * item.unitPrice;
      data_local.value.amount += item.amount;
      products.push(item);
    }
  });
  data_local.value.products = [...products];
}

const calculateTotalPaid = line => {
  data_local.value.totalPaid = 0;
  data_local.value.cashPayments.forEach(item => {
    if (item.amount < 0) {
      item.amount = Math.abs(item.amount);
    }
    if (item.code != line.code && allowMixedPayments) {
      item.amount = 0;
    }
    data_local.value.totalPaid += item.amount;
  });
}

const priceOrder = () => {
  calculateAmount();
}

const toPayment = () => {
  if (data_local.value.amount <= 0) {
    dialog.value.show('error', 'Error validando información', 'Orden no puede tener valor cero');
  } else {
    currentTabIndex.value = 2;
  }
}

const placeOrder = () => {
  if (data_local.value.amount <= 0) {
    dialog.value.show('error', 'Error validando información', 'Orden no puede tener valor cero');
  } else if (data_local.value.totalPaid < data_local.value.amount) {
    dialog.value.show('error', 'Error validando información', 'Total pagado no puede ser menor a valor de orden');
  } else {
    placeOrderPulse();
  }
}

const placeOrderPulse = () => {
  placeOrderDisabled.value = true;
  data_local.value.coupons = [];
  data_local.value.context = store.userInfo;
  data_local.value.originWarehouseId = 1;
  data_local.value.statusId = 'OPE';
  data_local.value.documentTypeId = 1;
  data_local.value.documentTypeFunction = 'VEN';
  data_local.value.locationId = 1;
  data_local.value.origin = 'FW';
  const loading = loader.show({ loader: 'bars', color: '#0000FF' }, { after: () => 'Colocando orden, espere por favor' });
  // const apivalue = 'http://localhost:8080/v2';
  axios.post(`${api.value}/sale/pos/place_order`, data_local.value)
  .then(response => {
    placeOrderDisabled.value = false;
    loading.hide();
    if (response.data.success) {
      dialogPlacedOrder.value.show('success', 'Orden colocada correctamente', `Orden en FastWay: ${response.data.data.id}, Orden en Pulse: ${response.data.data.purchaseOrder}`);
    } else {
      dialog.value.show('error', 'Error interno al colocar orden', `Orden en FastWay: ${response.data.data.id}, Orden en Pulse: ${response.data.data.purchaseOrder}, Error: ${response.data.message}`);
    }
  })
  .catch(error => {
    placeOrderDisabled.value = false;
    loading.hide();
    var errorMessage = '';
    if (error.message) {
      errorMessage = error.message;
    } else if (error.response) {
      errorMessage = error.response.data.ErrorMessage;
    } else {
      errorMessage = 'Error de conectividad'
    }
    dialog.value.show('error', 'Error al colocar orden', errorMessage);
  });
}


const resetData = () => {
  data_local.value = {
    id: 0,
    phone: '',
    name: '',
    active: '',
    donor: '',
    society: '',
    autosale: '',
    vat: 'CF',
    vatName: 'Consumidor Final',
    date: new Date(),
    amount: 0,
    totalPaid: 0,
    change: 0,
    storeId: '',
    couponCode: '',
    products: [],
    coupons: [],
    cashPayments: []
  }
  coupons.value = [];
  paymentMethods.value.forEach(item => {
    data_local.value.cashPayments.push({
      paymentMethodId: item.id,
      paymentMethodCode: item.code,
      name: item.name,
      amount: 0
    });
  });
  placeOrderDisabled.value = false;
  currentTabIndex.value = 0;
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="live-preview">
              <div class="position-relative m-4">
                <div class="progress" style="max-height:  1px">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    style="width: 50%"
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <button
                  type="button"
                  class="
                    position-absolute
                    top-0
                    start-0
                    translate-middle
                    btn btn-sm
                    rounded-pill
                  "
                  :class="{'btn-primary': currentTabIndex == 0, 'btn-light': currentTabIndex != 0}"
                  style="width: 2rem; height: 2rem"
                >
                  1
                </button>
                <button
                  type="button"
                  class="
                    position-absolute
                    top-0
                    start-50
                    translate-middle
                    btn btn-sm btn-primary
                    rounded-pill
                  "
                  :class="{'btn-primary': currentTabIndex == 1, 'btn-light': currentTabIndex != 1}"
                  style="width: 2rem; height: 2rem"
                >
                  2
                </button>
                <button
                  type="button"
                  class="
                    position-absolute
                    top-0
                    start-100
                    translate-middle
                    btn btn-sm
                    rounded-pill
                  "
                  :class="{'btn-primary': currentTabIndex == 2, 'btn-light': currentTabIndex != 2}"
                  style="width: 2rem; height: 2rem"
                >
                  3
                </button>
              </div>
            </div>
            <div class="mt-4" v-if="currentTabIndex === 0">
              <h4 class="mb-3">Datos del cliente</h4>
              <div class="row mb-3">
                <div class="form-floating col-3">
                  <input type="text" class="form-control rounded-0 border-0 border-bottom border-secondary bg-white"
                    id="vat" v-model="data_local.vat"
                    placeholder="NIT"
                    @blur="onVATBlur"
                  />
                  <label for="vat">NIT</label>
                </div>
                <div class="form-floating col">
                  <input type="text" class="form-control rounded-0 border-0 border-bottom border-secondary bg-light"
                    id="name" v-model="data_local.vatName" disabled
                    placeholder="Nombre a facturar" />
                  <label for="vatName">Nombre a facturar</label>
                </div>
              </div>
              <div class="d-flex row mt-5">
                <div class="col d-flex justify-content-between">
                  <b-button variant="danger" class="btn-label waves-effect waves-light" v-b-tooltip.hover
                    title="Limpiar datos de orden" @click="confirm.show('Confirme', '¿Está seguro de eliminar los datos ingresados en esta orden?')"><i class="las la-backspace label-icon"></i> Limpiar</b-button>
                  <b-button variant="success" class="btn-label waves-effect waves-light" v-b-tooltip.hover
                    title="Seleccionar productos" @click="toProducts"><i class="las la-arrow-right label-icon"></i> Siguiente</b-button>
                </div>
              </div>
            </div>
            <div class="p-2 bg-light" v-if="currentTabIndex === 1">
              <div class="d-flex row">
                <div class="d-flex col-2 mx-2 card">
                  <div class="card-header align-items-center d-flex justify-content-between">
                    <b-button variant="light" class="position-relative p-0 avatar-sm rounded-circle">
                      <h1 class="avatar-title bg-transparent text-reset">
                          <i class="las la-shopping-cart"></i>
                      </h1>
                      <span v-if="data_local.products.length > 0" class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-success p-2 fs-45">{{ data_local.products.length }}<span class="visually-hidden">Productos</span></span>
                    </b-button>
                    <b-button pill variant="secondary" class="justify-content-end waves-effect waves-light" v-b-tooltip.hover title="Clic para recalcular precio" @click="priceOrder">Q{{ data_local.amount.toFixed(2) }}</b-button>
                  </div>
                  <div class="card-header align-items-center d-flex justify-content-center">
                    <b-button variant="success" class="btn-label waves-effect waves-light" v-b-tooltip.hover size="lg"
                    title="Finalizar orden" @click="toPayment"><i class="las la-arrow-right label-icon"></i> Finalizar</b-button>
                  </div>
                  <div class="card-body bg-light p-0">
                    <div v-for="item in data_local.products" :key="item.productId">
                      <div class="card mb-1">
                        <div class="card-body">
                            <span class="card-title mb-2">{{ item.productName }}</span>
                        </div>
                        <div class="card-footer justify-content-between container">
                          <div class="row align-middle">
                            <div class="col">
                              <input
                                type="number"
                                class="form-control form-control-sm"
                                v-model="item.quantity"
                                min="1"
                                :max="maxQuantity"
                                @change="priceOrder"
                              />
                            </div>
                            <div class="col mt-1">
                              x {{ item.unitPrice.toFixed(2) }}
                            </div>
                            <div class="col justify-content-end mt-1 text-end">
                              <b>{{ item.amount.toFixed(2) }}</b>
                            </div>
                            <b-button variant="danger" size="sm" class="col-1 btn-icon waves-effect waves-light" v-b-tooltip.hover title="Eliminar producto de la orden" @click="removeProduct(item)"><i class="las la-trash"></i></b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col me-2 card">
                  <div class="card-header align-items-start">
                    <div class="row mb-1">
                      <b-button-group>
                        <b-button v-for="item in categories" :key="item.id" :variant="item.id == currentCategory ? 'primary' : 'outline-primary'" data-bs-toggle="button" aria-pressed="false" @click="setCategory(item.id)">{{ item.name.toUpperCase() }}</b-button>
                      </b-button-group>
                    </div>
                    <div class="row">
                      <div class="input-group">
                        <input type="text" class="form-control form-control" placeholder="Buscar producto" v-model="search">
                        <b-button v-if="search != ''" variant="secondary" class="btn-icon waves-effect waves-light" v-b-tooltip.hover title="Limpiar frase de búsqueda" @click="search = ''"><i class="las la-times"></i></b-button>
                        <b-button variant="secondary" class="btn-icon waves-effect waves-light" v-b-tooltip.hover title="Buscar"><i class="las la-search"></i></b-button>
                      </div>
                    </div>
                  </div>
                  <div class="card-body row bg-light">
                    <div v-for="item in productsList" :key="item.code" class="col-3">
                      <div class="card">
                        <img
                          class="card-img-top img-fluid"
                          :src="item.smallImage"
                          :alt="item.name"
                        />
                        <div class="card-body">
                            <h4 class="card-title mb-2">{{ item.name }}</h4>
                            <p class="card-text">{{ item.description }}</p>
                        </div>
                        <div class="card-footer justify-content-between container">
                          <div class="row align-middle">
                            <div class="col">
                              <h4>Q{{ item.price }}</h4>
                            </div>
                            <div class="col"/>
                            <div class="col">
                              <b-button variant="primary" class="btn-label waves-effect waves-light justify-content-end" v-b-tooltip.hover title="Agregar producto a orden" @click="addToOrder(item)"><i class="las la-cart-plus label-icon"></i> Agregar</b-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex row mt-5">
                <div class="col d-flex justify-content-between">
                  <b-button variant="primary" class="btn-label waves-effect waves-light" v-b-tooltip.hover
                    title="Datos del colaborador" @click="currentTabIndex = 0"><i class="las la-arrow-left label-icon"></i> Anterior</b-button>
                  <b-button variant="danger" class="btn-label waves-effect waves-light" v-b-tooltip.hover
                    title="Cancelar orden" @click="confirm.show('Confirme', '¿Está seguro de cancelar esta orden?')"><i class="las la-backspace label-icon"></i> Cancelar</b-button>
                  <b-button variant="success" class="btn-label waves-effect waves-light" v-b-tooltip.hover
                    title="Finalizar orden" @click="toPayment"><i class="las la-arrow-right label-icon"></i> Siguiente</b-button>
                </div>
              </div>
            </div>
            <div class="p-2 bg-light" v-if="currentTabIndex === 2">
              <div class="d-flex row">
                <div class="d-flex col-2 mx-2 card">
                  <div class="card-header align-items-center d-flex justify-content-between">
                    <b-button variant="light" class="position-relative p-0 avatar-sm rounded-circle">
                      <h1 class="avatar-title bg-transparent text-reset">
                          <i class="las la-shopping-cart"></i>
                      </h1>
                      <span v-if="data_local.products.length > 0" class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-success p-2 fs-45">{{ data_local.products.length }}<span class="visually-hidden">Productos</span></span>
                    </b-button>
                    <b-button pill variant="secondary" class="justify-content-end waves-effect waves-light" >Q{{ data_local.amount.toFixed(2) }}</b-button>
                  </div>
                  <div class="card-body p-0">
                    <div v-for="item in data_local.products" :key="item.productId">
                      <div class="card border mb-1">
                        <div class="card-body">
                            <span class="card-title mb-2">{{ item.productName }}</span>
                        </div>
                        <div class="card-footer bg-light justify-content-between container">
                          <div class="row align-middle">
                            <div class="col">
                              <input
                                type="number"
                                class="form-control form-control-sm"
                                v-model="item.quantity"
                                min="1"
                                :max="maxQuantity"
                                readonly
                              />
                            </div>
                            <div class="col mt-1">
                              x {{ item.unitPrice.toFixed(2) }}
                            </div>
                            <div class="col justify-content-end mt-1 text-end">
                              <b>{{ item.amount.toFixed(2) }}</b>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col me-2 card">
                  <div class="card-header align-items-start">
                    <h3>Formas de pago</h3>
                  </div>
                  <div class="card-body bg-light">
                    <div v-for="item in data_local.cashPayments" :key="item.code" class="container">
                      <div class="row mb-2" v-show="(item.code != 'DM' || data_local.active == 'X') && (item.code != 'AC' || data_local.autoSale == 'X')">
                        <div class="col bg-secondary text-white fw-bold border rounded align-middle fs-5 pt-2">
                          {{ item.name }}
                        </div>
                        <div class="col-2">
                          <input
                            type="number"
                            class="form-control form-control-lg text-end"
                            v-model="item.amount"
                            min="0"
                            max="1000"
                            @change="calculateTotalPaid(item)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-4 me-2 card">
                  <div class="card-header align-items-start">
                    <h3>Resumen de orden</h3>
                  </div>
                  <div class="card-body">
                    <div class="form-floating mb-1">
                      <input type="text" class="form-control border-secondary bg-light"
                        id="paymentVat" v-model="data_local.vat" disabled />
                      <label for="paymentVat">NIT</label>
                    </div>
                    <div class="form-floating mb-1">
                      <input type="text" class="form-control border-secondary bg-light"
                        id="paymentVatName" v-model="data_local.vatName" disabled />
                      <label for="paymentVatName">Nombre a facturar</label>
                    </div>
                    <hr/>
                    <div class="row px-2">
                      <span class="col fs-5">Total a pagar</span>
                      <span class="col-3 fs-5 fw-bold text-end text-secondary">Q{{data_local.amount.toFixed(2)}}</span>
                    </div>
                    <div class="row px-2">
                      <span class="col fs-5">Total pagado</span>
                      <span class="col-3 fs-5 fw-bold text-end">Q{{data_local.totalPaid.toFixed(2)}}</span>
                    </div>
                    <div class="row px-2">
                      <span class="col fs-5">Cambio</span>
                      <span class="col-3 fs-5 fw-bold text-end text-success">Q{{change.toFixed(2)}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex row mt-5">
                <div class="col d-flex justify-content-between">
                  <b-button variant="primary" class="btn-label waves-effect waves-light" v-b-tooltip.hover
                    title="Productos" @click="currentTabIndex--"><i class="las la-arrow-left label-icon"></i> Anterior</b-button>
                  <b-button variant="danger" class="btn-label waves-effect waves-light" v-b-tooltip.hover
                    title="Cancelar orden" @click="confirm.show('Confirme', '¿Está seguro de cancelar esta orden?')"><i class="las la-backspace label-icon"></i> Cancelar</b-button>
                  <b-button variant="success" class="btn-label waves-effect waves-light" v-b-tooltip.hover :disabled="placeOrderDisabled"
                    title="Finalizar orden" @click="placeOrder"><i class="las la-cash-register label-icon"></i> Colocar orden</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Dialog ref="dialog"/>
    <Dialog ref="dialogPlacedOrder" @accept="resetData"/>
    <Confirm ref="confirm" @accept="resetData"/>
  </Layout>
</template>
