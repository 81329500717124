<script setup>
import { ref } from "vue";
import axios from 'axios';
import Dialog from '@/views/components/Dialog.vue';
import { useRouter } from 'vue-router';
import { useFastWay } from "@/state/modules/fastway";

const apiFW = require('@/api.config.js');
const apiURL = apiFW.default.apiURL;
const store = useFastWay();
const router = useRouter();

let userName = ref('');
let password = ref('');
let loginEnabled = ref(true);
let dialog = ref(null);

const sendMagicLink = async () => {
  loginEnabled.value = false;
  const loginData = {
    login: userName.value,
    password: password.value
  };
  await axios
  .post(`${apiURL}/users/login`, loginData)
  .then(response => {
    if (response.data.success) {
      if (response.data.data.token == '') {
        dialog.value.show('error', 'Acceso denegado', 'Acceso denegado a usuario indicado, verifique credendiales');
      } else { 
        store.userInfo = {
          uid: response.data.data.id,
          userId: response.data.data.id,
          login: response.data.data.login,
          userName: response.data.data.login,
          displayName: response.data.data.name,
          branchId: response.data.data.branchId,
          banchName: response.data.data.banchName,
          token: response.data.data.token,
        };
        router.push({name: 'home'});
      }
    } else {
      dialog.value.show('error', 'Acceso denegado', response.data.message);
    }
    loginEnabled.value = true;
  })
  .catch(error => {
    loginEnabled.value = true;
    dialog.value.show('error', 'Acceso denegado', error.message);
  });
};
</script>

<template>
  <!-- auth-page wrapper -->
  <!-- auth-page-wrapper auth-bg-cover -->
  <div class="
      auth-page-wrapper 
      py-5
      d-flex
      justify-content-center
      align-items-center
      min-vh-100
    ">
    <div class="bg-overlay"></div>
    <!-- auth-page content -->
    <div class="auth-page-content overflow-hidden pt-lg-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="card overflow-hidden">
              <div class="row g-0">
                <div class="col-lg-6">
                  <div class="p-lg-5 p-2 auth-one-bg h-100">
                    <div class="bg-overlay"></div>
                    <div class="position-relative h-100 d-flex flex-column">
                      <div class="mb-4">
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end col -->

                <div class="col-lg-6">
                  <div class="p-lg-5 p-4">
                    <div>
                      <div class="mb-5 w-100 d-flex justify-content-center">
                        <img src="@/assets/images/logo.png" alt="" height="90" />
                      </div>
                      <h2 class="text-primary">FastWay Pro</h2>
                      <h5 class="text-primary">Bienvenido</h5>
                      <p class="text-muted">
                        Escriba las credenciales asignadas para aceder al sistema
                      </p>
                    </div>

                    <div class="mt-4">
                      <div>
                        <div class="mb-3">
                          <label for="email" class="form-label">Usuario</label>
                          <input type="text" class="form-control" id="email" placeholder="Usuario registrado"
                            v-model="userName" />
                        </div>
                        <div class="mb-3">
                          <label for="password" class="form-label">Contraseña</label>
                          <input type="password" class="form-control" id="password" placeholder="Clave"
                            v-model="password" />
                        </div>

                        <div class="mt-4">
                          <button v-if="loginEnabled" class="btn btn-success w-100" @click="sendMagicLink">
                            Acceder
                          </button>
                          <button v-else class="btn btn-success w-100">
                            <span class="d-flex align-items-center">
                              <span class="spinner-border flex-shrink-0" role="status">
                                <span class="visually-hidden">Validando acceso...</span>
                              </span>
                              <span class="flex-grow-1 ms-2">
                                Validando acceso...
                              </span>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end col -->
              </div>
              <!-- end row -->
            </div>
            <!-- end card -->
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end auth page content -->

    <!-- footer -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0">
                &copy; {{ new Date().getFullYear() }} acentoNET
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end Footer -->
    <Dialog ref="dialog"/>
  </div>
  <!-- end auth-page-wrapper -->
</template>

<style lang="scss">
.auth-bg-cover {
  background: linear-gradient(-45deg, rgb(220, 220, 220), rgb(0, 0, 50));

  >.bg-overlay {
    background-image: url("~@/assets/images/cover-pattern.png");
    background-position: center;
    background-size: cover;
    opacity: 1;
    background-color: transparent;
  }

  .footer {
    color: rgba(white, .5);
  }
}
</style>