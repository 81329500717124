<script>
  import { layoutComputed } from "@/state/helpers";
  import { SimpleBar } from "simplebar-vue3";
  import { useFastWay } from "@/state/modules/fastway";
  const apiFW = require('@/api.config.js');
  const apiURL = apiFW.default.apiURL;

  export default {
    setup() {
      const store = useFastWay();
      return {
        store
      }
    },
    components: {
      SimpleBar
    },
    data() {
      return {
        settings: {
          minScrollbarLength: 60,
        },
        options: []
      };
    },
    computed: {
      ...layoutComputed,
      layoutType: {
        get() {
          return this.$store ? this.$store.state.layout.layoutType : {} || {};
        },
      }
    },

    watch: {
      $route: {
        handler: "onRoutechange",
        immediate: true,
        deep: true,
      },
    },

    created() {
      const self = this;
      this.$http
        .post(`${apiURL}/users/options_full`, {userId: this.store.userInfo.userId, token: this.store.userInfo.token})
        .then(response => {
          if (response.data.success) {
            self.options = response.data.data;
          } else {
            self.$swal.fire({
                icon: 'error',
                title: 'Error al cargar opciones de usuario',
                text: response.data.message,
                confirmButtonText: 'Aceptar'
            });
          }
        })
        .catch(error => {
          self.$swal.fire({
              icon: 'error',
              title: 'Error al cargar opciones de usuario',
              text: error.message,
              confirmButtonText: 'Aceptar'
          });
        });
    },

    mounted() {
      if (document.querySelectorAll(".navbar-nav .collapse")) {
        let collapses = document.querySelectorAll(".navbar-nav .collapse");
        collapses.forEach((collapse) => {
          // Hide sibling collapses on `show.bs.collapse`
          collapse.addEventListener("show.bs.collapse", (e) => {
            e.stopPropagation();
            let closestCollapse = collapse.parentElement.closest(".collapse");
            if (closestCollapse) {
              let siblingCollapses =
                closestCollapse.querySelectorAll(".collapse");
              siblingCollapses.forEach((siblingCollapse) => {
                if (siblingCollapse.classList.contains("show")) {
                  siblingCollapse.classList.remove("show");
                }
              });
            } else {
              let getSiblings = (elem) => {
                // Setup siblings array and get the first sibling
                let siblings = [];
                let sibling = elem.parentNode.firstChild;
                // Loop through each sibling and push to the array
                while (sibling) {
                  if (sibling.nodeType === 1 && sibling !== elem) {
                    siblings.push(sibling);
                  }
                  sibling = sibling.nextSibling;
                }
                return siblings;
              };
              let siblings = getSiblings(collapse.parentElement);
              siblings.forEach((item) => {
                if (item.childNodes.length > 2)
                  item.firstElementChild.setAttribute("aria-expanded", "false");
                let ids = item.querySelectorAll("*[id]");
                ids.forEach((item1) => {
                  item1.classList.remove("show");
                  if (item1.childNodes.length > 2) {
                    let val = item1.querySelectorAll("ul li a");

                    val.forEach((subitem) => {
                      if (subitem.hasAttribute("aria-expanded"))
                        subitem.setAttribute("aria-expanded", "false");
                    });
                  }
                });
              });
            }
          });

          // Hide nested collapses on `hide.bs.collapse`
          collapse.addEventListener("hide.bs.collapse", (e) => {
            e.stopPropagation();
            let childCollapses = collapse.querySelectorAll(".collapse");
            childCollapses.forEach((childCollapse) => {
              let childCollapseInstance = childCollapse;
              childCollapseInstance.hide();
            });
          });
        });
      }
    },

    methods: {
      onRoutechange(ele) {
        this.initActiveMenu(ele.path);
        if (document.getElementsByClassName("mm-active").length > 0) {
          const currentPosition = document.getElementsByClassName("mm-active")[0].offsetTop;
          if (currentPosition > 500)
            if (this.$refs.isSimplebar)
              this.$refs.isSimplebar.value.getScrollElement().scrollTop = currentPosition + 300;
        }
      },

      initActiveMenu(ele) {
        setTimeout(() => {
          if (document.querySelector("#navbar-nav")) {
            let a = document.querySelector("#navbar-nav").querySelector('[href="' + ele + '"]');

            if (a) {
              a.classList.add("active");
              let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
              if (parentCollapseDiv) {
                parentCollapseDiv.classList.add("show");
                parentCollapseDiv.parentElement.children[0].classList.add("active");
                parentCollapseDiv.parentElement.children[0].setAttribute("aria-expanded", "true");
                if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
                  parentCollapseDiv.parentElement.closest(".collapse").classList.add("show");
                  if (parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling)
                    parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.classList.add(
                      "active");
                }
              }
            }
          }
        }, 0);
      },
    },
  };
</script>

<template>
  <div class="container-fluid">
    <div id="two-column-menu"></div>

    <template v-if="layoutType === 'twocolumn'">
      <SimpleBar class="navbar-nav" id="navbar-nav">
        <li class="menu-title">
          <span>Menú</span>
        </li>
      </SimpleBar>
    </template>

    <template v-else>
      <ul class="navbar-nav h-100" id="navbar-nav">
        <div v-for="mainMenu of options" :key="mainMenu.id">
          <li class="menu-title">
            <span>{{ mainMenu.title }}</span>
          </li>
          <li class="nav-item" v-for="subMenu1 of mainMenu.children" :key="subMenu1.id">
            <a class="nav-link menu-link" :href="`#${subMenu1.route}`" data-bs-toggle="collapse" role="button"
              aria-expanded="false" :aria-controls="subMenu1.route">
              <i :class="subMenu1.icon"></i>
              <span>{{ subMenu1.title }}</span>
            </a>
            <div v-if="subMenu1.hasChildren" class="collapse menu-dropdown" :id="subMenu1.route">
              <ul class="nav nav-sm flex-column">
                <li class="nav-item" v-for="subMenu2 of subMenu1.children" :key="subMenu2.id">
                  <router-link :to="subMenu2.url" class="nav-link custom-abc" data-key="t-analytics">
                    {{ subMenu2.title }}
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
        </div>
      </ul>
    </template>
  </div>
  <!-- Sidebar -->
</template>