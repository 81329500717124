<script setup>
import { ref, computed, onMounted } from "vue";
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios';
import { useLoading } from 'vue-loading-overlay'
import Layout from "@/layouts/mainfw.vue";
import PageHeader from "@/components/page-header";
import { useFastWay } from "@/state/modules/fastway";
import Dialog from '@/views/components/Dialog.vue';
import Confirm from '@/views/components/Confirm.vue';
import { TextBoxComponent } from "@syncfusion/ej2-vue-inputs";
import { DropDownListComponent } from "@syncfusion/ej2-vue-dropdowns";
import { NumericTextBoxComponent } from "@syncfusion/ej2-vue-inputs";

const apiFW = require('@/api.config.js');
const router = useRouter();
const route = useRoute();
const store = useFastWay();
const loader = useLoading();
let id = ref(0);
let loaded = ref(false);
let editing = ref(false);
let dialog = ref(null);
let dialogDelete = ref(null);
let confirm = ref(null);
let data = ref(null);
let types = ref([]);
let statuses = ref([]);
let data_local = ref({
  id: 0,
  code: '',
  name: '',
  typeId: 1
});
const title = "Formas de pago";
const items = [
  {
    text: "Ventas",
    href: "/",
  },
  {
    text: "Formas de pago",
    href: "/sales/catalogs/payment_methods",
  },
  {
    text: "Formas de pago",
    active: true,
  }
];

const api = computed({
    get() {
      return apiFW.default.apiURL;
    }
});

onMounted(async () => {
  id.value = parseInt(route.params.id);
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Cargando tipos'});
  await axios
  .post(`${api.value}/lists/get_list`, {
    userId: store.userInfo.userId,
    token: store.userInfo.token,
    list: 'TIPOFORMAPAGO'
  })
  .then(response => {
    if (response.data.success) {
      types.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error interno al cargar tipos', response.data.message);
    }
    return axios.post(`${api.value}/lists/get_list`, {
      userId: store.userInfo.userId,
      token: store.userInfo.token,
      list: 'ESTADOFORMAPAGO'
    });
  })
  .then(response => {
    if (response.data.success) {
      statuses.value = response.data.data;
      getData(id.value);
    } else {
      dialog.value.show('error', 'Error interno al cargar tipos', response.data.message);
    }
    loading.hide();
  })
  .catch(error => {
      let errorMessage = '';
      if (error.message) {
        errorMessage = error.message;
      } else if (error.response) {
        errorMessage = error.response.data.ErrorMessage;
      } else {
        errorMessage = 'Error de conectividad al cargar catálogo';
      }
      loading.hide();
      dialog.value.show('error', 'Error al cargar tipos', errorMessage);
  });
});

const getData = async (id) => {
  if (id == '0') {
    loaded.value = true;
    editing.value = true;
  } else {
    const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Cargando datos de forma de pago'});
    await axios
    .post(`${api.value}/sale/cash_payments/data`, {id, context: store.userInfo})
    .then(response => {
      if (response.data.success) {
        if (response.data.data) {
          data.value = response.data.data;
          data_local.value = JSON.parse(JSON.stringify(response.data.data));
        }
      } else {
        dialog.value.show('error', 'Error interno al cargar forma de pago', response.data.message);
      }
      loaded.value = true;
      loading.hide();
    })
    .catch(error => {
      loaded.value = true;
      loading.hide();
      dialog.value.show('error', `Error al cargar forma de pago ${id}`, error.message);
    });
  }
};

const resetData = () => {
  if (id.value == '0') {
    router.push({name: 'paymentMethods'});
  } else {
    data_local.value = JSON.parse(JSON.stringify(data.value));
    editing.value = false;
  }
};

const validateForm = () => {
  let validated = false;
  if (!data_local.value.code || data_local.value.code == '') {
    dialog.value.show('error', 'Error al guardar forma de pago', 'Debe especificar un código');
  } else if (!data_local.value.name || data_local.value.name == '') {
    dialog.value.show('error', 'Error al guardar forma de pago', 'Debe especificar nombre para la forma de pago');
  } else {
    validated = true;
  }
  return validated;
};

const save = async () => {
  if (validateForm()) {
    const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Guardando datos de forma de pago'});
    data_local.value.id = id.value;
    data_local.value.context = store.userInfo;
    data_local.value.createdBy = store.userInfo.userId;
    await axios
    .post(`${api.value}/sale/cash_payments/save`, data_local.value)
    .then(response => {
      loading.hide();
      if (response.data.success) {
        data.value = JSON.parse(JSON.stringify(data_local.value));
        editing.value = false;
        dialog.value.show('success', 'Información', 'Datos han sido actualizados');
        if (id.value== 0) {
          router.push({
            name: 'paymentMethod',
            params: {id: response.data.data.id}
          });
        }
      } else {
        dialog.value.show('error', 'Error al actualizar', response.data.message);
      }
    })
    .catch(error => {
      loading.hide();
      dialog.value.show('error',  `Error al actualizar forma de pago ${id.value}`, error.message);
    });
  }
};

const remove = async () => {
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Eliminando forma de pago'});
  const parms = {
    id: id.value,
    context: store.userInfo,
    createdBy: store.userInfo.userId
  };
  await axios
  .post(`${api.value}/sale/cash_payments/delete`, parms)
  .then(response => {
    loading.hide();
    if (response.data.success) {
      dialogDelete.value.show('success', 'Información', 'Forma de pag fue eliminada correctamente');
    } else {
      dialog.value.show('error', 'Error al eliminar', response.data.message);
    }
  })
  .catch(error => {
    loading.hide();
    dialog.value.show('error', `Error al eliminar forma de pag ${id.value}`, error.message);
  });
};

const add = () => {
  router.push({
    name: 'paymentMethod',
    params: {id: 0}
  });
  id.value = 0;
  data_local.value = {
    id: 0,
    code: '',
    name: '',
    typeId: 1
  };
  editing.value = true;
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
     <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center align-middle row ms-0 me-0 mb-2">
                        <div v-if="!editing" class="col d-flex justify-content-start">
                          <b-button variant="success" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Agregar forma de pago" @click="add"><i class="las la-plus label-icon"></i> Agregar</b-button>
                        </div>
                        <div class="col"/>
                        <div class="input-group col justify-content-end">
                          <b-button v-if="editing" variant="primary" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Guardar cambios efectuados" @click="save"><i class="las la-save label-icon"></i> Guardar</b-button>
                          <b-button v-if="editing" variant="warning" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Descartar cambios efectuados" @click="resetData"><i class="las la-undo label-icon"></i> Cancelar</b-button>
                          <b-button v-if="!editing" variant="primary" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Editar información" @click="editing = true"><i class="las la-edit label-icon"></i> Editar</b-button>
                          <b-button v-if="!editing" variant="danger" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Eliminar forma de pag" @click="confirm.show('Confirme', '¿Está seguro de eliminar esta forma de pago?')"><i class="las la-trash label-icon"></i> Eliminar</b-button>
                        </div>
                    </div>
                    <hr>
                    <div class="row mb-4">
                      <div class="col-4">
                        <div class="form-floating">
                          <input type="text" class="form-control form-control-lg" :class="{'bg-light': !editing, 'bg-white': editing}" id="code" v-model="data_local.code" placeholder="Código" :disabled="!editing"/>
                          <label for="code">Código</label>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-1">
                      <div class="col">
                        <TextBoxComponent v-model:value="data_local.name" floatLabelType="Auto" placeholder="Nombre" :disabled="!editing"/>
                      </div>
                    </div>
                    <div class="row mb-1">
                      <div class="col-1">
                        <NumericTextBoxComponent v-model:value="data_local.order" floatLabelType="Auto" placeholder="Orden" format="N0" min=0 max=999999 :enabled="editing"/>
                      </div>
                      <div class="col-2">
                        <DropDownListComponent
                            v-model:value="data_local.typeId"
                            floatLabelType="Auto"
                            placeholder="Tipo"
                            highlight=true
                            :dataSource="types"
                            :fields="{text: 'value', value: 'id'}"
                            :showClearButton='true'
                            :enabled="editing"
                          />
                      </div>
                      <div class="col-3">
                        <DropDownListComponent
                            v-model:value="data_local.statusId"
                            floatLabelType="Auto"
                            placeholder="Estado"
                            highlight=true
                            :dataSource="statuses"
                            :fields="{text: 'value', value: 'code'}"
                            :showClearButton='true'
                            :enabled="editing"
                          />
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Dialog ref="dialog"/>
    <Dialog ref="dialogDelete" @accept="router.push({name: 'paymentMethods'});"/>
    <Confirm ref="confirm" @accept="remove"/>
  </Layout>
</template>

<style>
@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-inputs/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";

.e-input-group .e-input[disabled], .e-input-group.e-control-wrapper .e-input[disabled], .e-input-group.e-disabled, .e-input-group.e-control-wrapper.e-disabled, .e-float-input input[disabled], .e-float-input.e-control-wrapper input[disabled], .e-float-input textarea[disabled], .e-float-input.e-control-wrapper textarea[disabled], .e-float-input.e-disabled, .e-float-input.e-control-wrapper.e-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.75);
    color: rgba(0, 0, 0, 0.75);
}
</style>