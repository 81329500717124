<template>
 <router-view></router-view>

</template>

<script>
import axios from 'axios';
import { useFastWay } from "@/state/modules/fastway";
const apiFW = require('@/api.config.js');

export default {
  name: 'App',
  components: {
  },
  created() {
    this.getParameters();
  },
  methods: {
    getParameters() {
      const apiURL = apiFW.default.apiURL;
      const store = useFastWay();
      const context = {userId: store.userInfo.userId, token: store.userInfo.token};
      axios
      .post(`${apiURL}/parameters/list`, context)
      .then(response => {
        if (response.data.success) {
          if (response.data.data) {
            store.parametersFW = response.data.data;
          }
        }
        return axios
          .post(`${apiURL}/infra/regions/countries`, context);
      })
      .then(response => {
        if (response.data.success) {
          store.countries = response.data.data;
        }
        return axios
          .post(`${apiURL}/infra/regions/states`, context);
      })
      .then(response => {
        if (response.data.success) {
          store.states = response.data.data;
        }
        return axios
          .post(`${apiURL}/infra/regions/cities`, context);
      })
      .then(response => {
        if (response.data.success) {
          store.cities = response.data.data;
        }
      })
      .catch(error => {
        console.log(error.message);
      });
    }
  }
}
</script>
